import React, { useState, useEffect } from "react";
import Slider from "react-slider";
import Sliderx from "react-slick";
import LeadRegister from "./LeadRegister";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import SBI from "../assets/images/sbi.png";
import HDFC from "../assets/images/hdfc.png";
import Axis from "../assets/images/axis.png";
import Canara from "../assets/images/canara.png";
import ICICI from "../assets/images/icici.png";
import Kotak from "../assets/images/kotak.png";
import LIC from "../assets/images/LIC.png";
import Yesbank from "../assets/images/yesbank.png";
import { PRIMARY_COLOR } from "../constant";

const HomeLoanCalculator = () => {
  const images = [SBI, Axis, HDFC, Canara, ICICI, Kotak, LIC, Yesbank];

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          color: "white",
          backgroundColor: "grey",
          borderRadius: 40,
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          color: "white",
          backgroundColor: "grey",
          borderRadius: 40,
        }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 3,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          infinite: true,
          //  dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      //   {
      //     breakpoint: 480,
      //     settings: {
      //       slidesToShow: 1,
      //       slidesToScroll: 1,
      //     },
      //   },
    ],
  };

  const [loanAmount, setLoanAmount] = useState(1000000); // 10 lakh
  const [tenure, setTenure] = useState(5); // 5 years
  const [interestRate, setInterestRate] = useState(5); // 5%

  const calculateEMI = (principal, rate, time) => {
    const monthlyRate = rate / (12 * 100);
    const numberOfMonths = time * 12;
    const emi =
      (principal * monthlyRate * Math.pow(1 + monthlyRate, numberOfMonths)) /
      (Math.pow(1 + monthlyRate, numberOfMonths) - 1);
    return emi;
  };

  const emi = calculateEMI(loanAmount, interestRate, tenure);
  const totalAmountPayable = emi * tenure * 12;
  const totalInterest = totalAmountPayable - loanAmount;

  const datax = [
    { name: "Principal Amount", value: loanAmount },
    { name: "Total Interest", value: totalInterest },
  ];

  const COLORS = ["#FFBB28", "#FF8042"];

  return (
    <div className=" mt-10 py-14 sm:py-22 p-4 sm:px-8 relative w-[95vw] sm:w-[75vw] lg:w-[45vw] max-w-7xl  bg-cover border border-gray-300 rounded-md shadow-md bg-white py-4">
      <div className="mb-6 flex max-w-7xl flex-row justify-between sm:items-center sm:flex-colum">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h1 className="text-xl text-gray-800 font-semibold">
            Home Loan Calculator
          </h1>

          <div
            style={{
              width: "5vw",
              backgroundColor: PRIMARY_COLOR,
              height: 3,
              marginTop: 10,
            }}
          ></div>
        </div>
      </div>
      <div className="flex flex-col 4xl:flex-row p-4 max-w-screen-lg mx-auto">
        <div className="flex-1 flex flex-col gap-4 p-4">
          <div>
            <h3 className="text-lg text-gray-800  font-semibold">
              Loan Amount
            </h3>
            <h3 className="text-lg text-gray  font-semibold">
              ₹{loanAmount.toLocaleString()}
            </h3>
            <Slider
              value={loanAmount}
              min={1000000}
              max={10000000}
              step={100000}
              onChange={(value) => setLoanAmount(value)}
              className="w-full h-10 flex items-center"
              thumbClassName="w-6 h-6 bg-indigo-600 rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-opacity-50"
              trackClassName="h-1.5 bg-gray-300"
            />
          </div>

          <div>
            <h3 className="text-lg text-gray-800  font-semibold">
              Loan Tenure
            </h3>
            <h3 className="text-lg text-gray  font-semibold">{tenure} years</h3>
            <Slider
              value={tenure}
              min={5}
              max={30}
              step={1}
              onChange={(value) => setTenure(value)}
              className="w-full h-10 flex items-center"
              thumbClassName="w-6 h-6 bg-indigo-600 rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-opacity-50"
              trackClassName="h-1.5 bg-gray-300"
            />
          </div>

          <div>
            <h3 className="text-lg text-gray-800  font-semibold">
              Interest Rate
            </h3>
            <h3 className="text-lg text-gray  font-semibold">
              {interestRate}%
            </h3>

            <Slider
              value={interestRate}
              min={5}
              max={15}
              step={0.1}
              onChange={(value) => setInterestRate(value)}
              className="w-full h-10 flex items-center"
              thumbClassName="w-6 h-6 bg-indigo-600 rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-opacity-50"
              trackClassName="h-1.5 bg-gray-300"
            />
          </div>
          <LeadRegister isShow={false} title="Enquiry Now" />

          {/* <button className="px-4 py-3 text-sm text-white duration-100 bg-indigo-600 rounded-lg shadow-md focus:shadow-none ring-offset-2 ring-indigo-600 focus:ring-2">
            Enquiry Now
          </button> */}
        </div>

        <div className="flex-1 flex flex-col gap-4 p-4">
          <div className="flex flex-col md:flex-row gap-4">
            <div className="flex-1 flex flex-col items-center p-4 border rounded-lg shadow-sm">
              <h4 className="mb-2 text-lg text-black font-semibold">
                Home Loan EMI
              </h4>
              <p className="text-2xl">₹{emi.toFixed(2)}</p>
            </div>
            <div className="flex-1 flex flex-col items-center p-4 border rounded-lg shadow-sm">
              <h4 className="mb-2 text-lg text-black font-semibold">
                Total Payable
              </h4>
              <p className="text-2xl">₹{totalAmountPayable.toFixed(2)}</p>
            </div>
          </div>
          <div className="flex-1 flex flex-col items-center p-4  rounded-lg shadow-sm">
            <h4 className="mb-4 text-xl">Loan Breakdown</h4>
            <PieChart width={400} height={400}>
              <Pie
                data={datax}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={100}
                fill="#8884d8"
                label
              >
                {datax.map((entry, index) => (
                  <Cell
                    style={{}}
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </div>
        </div>
      </div>
      <h3 className="text-md text-gray-800 mx-4 my-4 font-semibold">
        Banks offering Home Loans
      </h3>
      <div className=" bottom-4 w-[90vw] sm:w-[75vw] md:w-[65vw] lg:w-[40vw] max-w-7xl px-4">
        <Sliderx {...settings}>
          {images.map((img, index) => (
            <div
              key={index}
              className=" flex items-center gap-2 cursor-pointer bg-white rounded-lg shadow-lg"
            >
              <div className=" w-full p-2  border border-gray-200">
                <img
                  src={img}
                  alt=""
                  //  style={{ height: 30 }}
                  className="w-full  rounded-md"
                />
              </div>
            </div>
          ))}
        </Sliderx>
      </div>
    </div>
  );
};

export default HomeLoanCalculator;
