import React,{useEffect} from "react";
import { useRef, useState } from "react";
import { PRIMARY_COLOR } from "../constant";

const FaqsCard = (props) => {
  const answerElRef = useRef();
  const [state, setState] = useState(false);
  const [answerH, setAnswerH] = useState("0px");
  const { faqsList, idx } = props;

  const handleOpenAnswer = () => {
    const answerElH = answerElRef.current.childNodes[0].offsetHeight;
    setState(!state);
    setAnswerH(`${answerElH + 20}px`);
  };

  return (
    <div
      className="space-y-3 mt-5 overflow-hidden border-b"
      key={idx}
      onClick={handleOpenAnswer}
    >
      <h4 className="cursor-pointer pb-5 flex items-center justify-between text-lg text-gray-700 font-medium">
        {faqsList.question}
        {state ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-gray-500 ml-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M20 12H4"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-gray-500 ml-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 4v16m8-8H4"
            />
          </svg>
        )}
      </h4>
      <div
        ref={answerElRef}
        className="duration-300"
        style={state ? { height: answerH } : { height: "0px" }}
      >
        <div>
          <p className="text-gray-500">{faqsList.answer}</p>
        </div>
      </div>
    </div>
  );
};

function PropertyFAQ({name, faqs}) {
 

    
  useEffect(() => {
    console.log(faqs[0]);
    console.log("sss");
  }, []);


  
  return (
    <div className=" mt-10 py-14 sm:py-22 p-4 sm:px-8 relative w-[95vw] sm:w-[75vw] lg:w-[45vw] max-w-7xl  bg-cover border border-gray-300 rounded-md shadow-md bg-white py-4">
      <div className="mb-6 flex max-w-7xl flex-row justify-between sm:items-center sm:flex-colum">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h1 className="text-xl text-gray-800 font-semibold">
             FAQs
          </h1>
          <div
            style={{
              width: "5vw",
              backgroundColor: PRIMARY_COLOR,
              height: 3,
              marginTop: 10,
            }}
          ></div>
        </div>
      </div>

      <div className="mt-14 max-w-7xl mx-auto">
        {faqs.map((item, idx) => (
          <FaqsCard idx={idx} faqsList={item} />
        ))}
      </div>
    </div>
  );
}

export default PropertyFAQ;
