import React, { useState, useEffect, useContext } from "react";
import "./ImageSlider.css"; // Make sure the path is correct for your CSS file
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import assetContext from "../context/AssetsContext/AssetContext";
import { IMAGE_PATH } from "../constant";

const ImageSlider = () => {
  const [assetsfeaturedx, setAssetsfeaturedx] = useState([]);
  const context = useContext(assetContext);
  const { assetsfeatured, getAssetsFeatured } = context;

  useEffect(() => {
    getAssetsFeatured({
      page: 1,
    });
  }, []);

  useEffect(() => {
    setAssetsfeaturedx(assetsfeatured);
  }, [assetsfeatured]);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [animationClass, setAnimationClass] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimationClass("slide-out-left");
      setTimeout(() => {
        setCurrentIndex(
          (prevIndex) => (prevIndex + 1) % assetsfeaturedx.length
        );
        setAnimationClass("slide-in-right");
      }, 500);
    }, 7000);

    return () => clearInterval(interval);
  }, [assetsfeaturedx.length]);

  const prevSlide = () => {
    setAnimationClass("slide-out-left");
    setTimeout(() => {
      setAssetsfeaturedx((prevImages) => {
        const lastImage = prevImages[prevImages.length - 1];
        const restImages = prevImages.slice(0, prevImages.length - 1);
        return [lastImage, ...restImages];
      });
      setCurrentIndex(0);
      setAnimationClass("slide-in-right");
    }, 500);
  };

  const nextSlide = () => {
    setAnimationClass("slide-out-left");
    setTimeout(() => {
      setAssetsfeaturedx((prevImages) => {
        const [firstImage, ...restImages] = prevImages;
        return [...restImages, firstImage];
      });
      setCurrentIndex(0);
      setAnimationClass("slide-in-right");
    }, 500);
  };

  const HandleClick = (slug) => {
    window.open(`/property/${slug}`, "_blank");
    localStorage.setItem("projectData", JSON.stringify(assetsfeaturedx));
  };

  return (
    <>
      {assetsfeaturedx.length > 0 ? (
        <div
          className="relative h-[70vh] bg-black sm:h-screen flex flex-col justify-between bg-cover bg-center transition-all duration-1000 ease-in-out"
          style={{
            overflowX: "hidden",
            width: "100%",
            backgroundImage: `url(${
              IMAGE_PATH +
              assetsfeaturedx[currentIndex].cover_image.fieldname +
              "/" +
              assetsfeaturedx[currentIndex].cover_image.filename
            })`,
          }}
        >
          <div
            className={`p-4 sm:p-8 m-2 sm:mt-8 bg-white bg-opacity-75 rounded-md shadow-lg w-[96vw] md:w-1/4 sm:w-full mt-20 md:ml-20 transition-opacity duration-1000 ease-in-out ${animationClass}`}
          >
            <h1
              className={`text-2xl sm:text-3xl font-bold mb-2 sm:mb-4 transition-transform duration-1000 ease-in-out transform ${animationClass}`}
            >
              {assetsfeaturedx[currentIndex].property_name}
            </h1>

            <div className="card-bottom w-full">
              <div className="flex text-md">
                {assetsfeaturedx[currentIndex].bedroom}
              </div>
              {/* <div className="flex text-sm">2000 sq.ft</div> */}
              <h1 className="text-md">
                {assetsfeaturedx[currentIndex].property_type},{" "}
                {assetsfeaturedx[currentIndex].property_dimension} sq.ft
              </h1>
              <div className="flex justify-between items-center mt-2 mb-4">
                <h3 className="text-black font-bold bold text-2xl">
                  ₹{assetsfeaturedx[currentIndex].property_price} Cr
                </h3>
                <h2 className="text-black text-md">
                  {assetsfeaturedx[currentIndex].location},{" "}
                  {assetsfeaturedx[currentIndex].city}
                </h2>
              </div>
            </div>

            <button
              onClick={() => HandleClick(assetsfeaturedx[currentIndex].slug)}
              className="px-4 py-2 bg-[#9379B8] text-white rounded "
            >
              Check Details
            </button>
          </div>
          <div className="absolute bottom-20 left-0 flex items-center justify-end w-1/2">
            <button
              className="bg-gray-700 text-white px-4 py-2 m-2 hover:bg-green hover:text-white"
              onClick={prevSlide}
            >
              <NavigateBeforeIcon />
            </button>

            <button
              className="bg-gray-700 text-white px-4 py-2 m-2 hover:bg-green hover:text-white"
              onClick={nextSlide}
            >
              <NavigateNextIcon />
            </button>
          </div>
          <div className="absolute bottom-10 right-0 flex items-center justify-center w-1/2">
            {/* <button className="bg-gray-700 text-white px-4 py-2 m-2" onClick={prevSlide}>Prev</button> */}
            {/* <button className="bg-gray-700 text-white px-4 py-2 m-2" onClick={nextSlide}>Next</button> */}

            <div className="flex overflow-hidden w-full">
              {assetsfeaturedx.map((project, index) => (
                <img
                  key={index}
                  src={
                    IMAGE_PATH +
                    project.cover_image.fieldname +
                    "/" +
                    project.cover_image.filename
                  }
                  className={`w-[200px] h-[250px] transition-transform duration-1000 ease-in-out ${
                    index === currentIndex
                      ? "transform scale-100"
                      : "transform scale-75"
                  }`}
                  style={{ borderRadius: "20px" }}
                />
              ))}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ImageSlider;
