// import { Button } from "@/components/ui/button";
import React from "react";

import BoxReveal from "./BoxRevilAnimation";

const BoxRevealDemo = () => {
  return (
    <div className="flex flex-col items-start justify-center overflow-hidden p-4">
      <BoxReveal boxColor={"#5046e6"} duration={0.5}>
        <p className="text-[3.5rem] font-semibold">Landmark Rewards</p>
      </BoxReveal>

      <BoxReveal boxColor={"#5046e6"} duration={0.5}>
        <h2 className="mt-[.5rem] text-[1rem]">
          Share the Dream of Owning Land and Get Rewarded!{" "}
        </h2>
      </BoxReveal>

      <BoxReveal boxColor={"#5046e6"} duration={0.5}>
        <div className="perks mb-6">
          <h3 className="text-xl font-semibold mb-3">Perks:</h3>
          <ul className="list-disc list-inside space-y-2">
            <li>
              <strong>₹50,000 Cash Reward:</strong> Receive ₹50,000 in cash for
              every successful referral.
            </li>
            <li>
              <strong>Double-Bonus Milestones:</strong> Earn an additional
              ₹25,000 for every 3 successful referrals.
            </li>
            <li>
              <strong>Priority Deals:</strong> Enjoy early access to upcoming
              premium listings and exclusive offers.
            </li>
            <li>
              <strong>Exclusive Community Events:</strong> Access to invite-only
              real estate seminars and property previews.
            </li>
          </ul>
        </div>
      </BoxReveal>
      <BoxReveal boxColor={"#5046e6"} duration={0.5}>
        <div className="conditions">
          <h3 className="text-xl font-semibold mb-3">Conditions:</h3>
          <ul className="list-disc list-inside space-y-2">
            <li>
              <strong>Eligibility:</strong> The referee must complete the entire
              purchase process for you to qualify for the reward.
            </li>
            <li>
              <strong>Verification Period:</strong> Rewards are credited within
              30 days after the successful closure of the property deal.
            </li>
            <li>
              <strong>Referral Limit:</strong> A maximum of 10 referrals per
              year for cash rewards; additional referrals may qualify for
              priority deals and other perks.
            </li>
            <li>
              <strong>Non-Transferable:</strong> Rewards are credited directly
              to the referrer and cannot be transferred.
            </li>
          </ul>
        </div>
      </BoxReveal>

      
    </div>
  );
};

export default BoxRevealDemo;
